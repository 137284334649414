import httpClient from '@/libs/http-client'

export function getUSDTWallet (params) {
  const url = '/api/usdt/walletAddress'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getUSDTExchangeURL (params) {
  const url = '/api/usdt/exchangeUrl'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getUSDTWithdraw (params) {
  const url = '/api/usdt/withdrawUSDT'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getUSDTWithdrawP2P (params) {
  const url = '/api/usdt/withdrawP2P'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
